import { Component, OnInit } from '@angular/core';
import {environment} from '../environments/environment';
import {BaseService} from './base.service';
import { Router } from '@angular/router';
import {SharedService} from './shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'mfit-app';
  expandSideBar = false;
  user:any;
  showSideBar = false;
  expandNotifications = false;
  menuIcon :string = "menu";

  constructor(private baseService:BaseService, private router:Router, private sharedService:SharedService){}

  ngOnInit(): void {
    // if localstorage has token, then clear the local storage for clearing first time app access after springboot release.
    // to prevent manual clearing localstorage by all clients.
    // Changed token name to jwtToken in login component.
    if(localStorage.token){
      localStorage.clear();
    }

    this.sharedService.loginSuccessful$.subscribe(result => {
        this.showSideBar = result;
      this.user = localStorage.user ? JSON.parse(localStorage.user) : {};
      this.newMethod();
    });
    this.newMethod();
    this.user = localStorage.user ? JSON.parse(localStorage.user) : {};
  }

  private newMethod() {
    if (localStorage.jwtToken) {
      this.validateToken();
    }
    else {
      this.showSideBar = false;
      this.router.navigate(['/login']);
    }
  }

  validateToken(){
      // // this.baseService.save(environment.restPath+"security/validate/token",{"token": localStorage.token}).subscribe((response)=>{
      // //   if(!response){
      // //     this.removeAuthStorage();
      // //   }
      // // });
      // this.removeAuthStorage();
      this.showSideBar = true;
  }

  loggedOut(){
      this.baseService.save(environment.restPath+"security/signout",this.user).subscribe((response)=>{
      this.removeAuthStorage();
      this.showSideBar = false;
      this.router.navigate(['/login']);

    });
  }


  removeAuthStorage(){
    localStorage.removeItem("user");
    localStorage.removeItem("jwtToken");
    this.user={}
    document.cookie = 'jwtToken' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  toggleMenu()
  {
     this.expandSideBar = !this.expandSideBar;
     if(this.expandSideBar)
     this.menuIcon = "menu_open"
     else if(!this.expandSideBar)
      this.menuIcon = "menu"

  }

  onClickNotifications()
  {
    this.expandNotifications = !this.expandNotifications;
  }

}
