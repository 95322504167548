import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(localStorage.getItem("jwtToken") != null)
    {
      var headers = req.headers.set('X-AuthToken', localStorage.getItem("jwtToken"));
      console.info('req.headers =', headers, ';');
      req = req.clone({headers});
    }
    return next.handle(req.clone({headers}))
      .map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && ~~(event.status / 100) > 3) {
          console.info('HttpResponse::event =', event, ';');
        } else console.info('event =', event, ';');
        return event;
      })
      .catch((err: any, caught) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 440) {
            console.info('err.error =', err.error, ';');
            confirm("Session Expired.Please Login Again");
            localStorage.removeItem("user");
            localStorage.removeItem("jwtToken");
            document.cookie = 'jwtToken' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            window.location.pathname = '/login.html';
          }
          return Observable.throw(err);
        }
      });
  }
}